export default class TarteAuCitron {
	constructor() {
		this.isInit         = false;
		const tarteaucitron = window.tarteaucitron;

		if ( ! this.isInit && tarteaucitron) {
			this.init( tarteaucitron );
		}
	}

	services(tarteaucitron) {
		// create custom services if needed
	}

	jobs(tarteaucitron) {
		tarteaucitron.job = tarteaucitron.job || [];

		tarteaucitron.user.googlemapsKey = process.env.GOOGLE_API_KEY;
		tarteaucitron.job.push( 'googlemaps' );
	}

	init(tarteaucitron) {
		tarteaucitron.init(
			{
				"privacyUrl": "", /* Privacy policy url */

				"hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
				"cookieName": "tarteaucitron", /* Cookie name */

				"orientation": "bottom", /* Banner position (top - bottom) */
				"showAlertSmall": false, /* Show the small banner on bottom right */
				"cookieslist": true, /* Show the cookie list */

				"adblocker": false, /* Show a Warning if an adblocker is detected */
				"DenyAllCta" : true, /* Show the deny all button */
				"AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
				"highPrivacy": false, /* Disable auto consent */
				"handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

				"removeCredit": true, /* Remove credit link */
				"moreInfoLink": false, /* Show more info link */
				"useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */

				"readmoreLink": "/cookiespolicy", /* Change the default readmore link pointing to tarteaucitron.io */

				"mandatory": false /* Show a message about mandatory cookies */
			}
		);

		this.services( tarteaucitron );
		this.jobs( tarteaucitron );
		this.isInit = true;
	};
}
