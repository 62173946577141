export default class Header {
  constructor() {
    this.init();
  }

  init() {
    this.menuNav = document.querySelector('.layout-header');
    this.manageBurgerMenu();
    this.manageDropdown();
    this.scrollListener();
  }

  scrollListener() {
    if (
      this.menuNav &&
      this.menuNav.classList.contains('layout-header--home')
    ) {
      document.addEventListener(
        'scroll',
        this.toggleHeaderBackground.bind(this),
        true
      );
    }
  }

  manageBurgerMenu() {
    // Open/Close with button menu
    const menuButton = document.querySelector('.layout-header__burger');

    menuButton.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      const menuNav = document.querySelector('.layout-header');
      menuNav.classList.toggle('is-open');
    });
  }

  manageDropdown() {
    // Open/Close with avatar
    const avatarButton = document.querySelector('.block-menu__avatar');

    if (!avatarButton) {
      return;
    }

    avatarButton.addEventListener('click', () => {
      const avatarNav = document.querySelector('.block-menu__item--avatar');
      avatarNav.classList.toggle('is-open');
    });
  }

  toggleHeaderBackground() {
    if (
      document.body.scrollTop > 30 ||
      document.scrollingElement.scrollTop > 30 ||
      document.documentElement.scrollTop > 30
    ) {
      this.menuNav.classList.remove('layout-header--home');
    } else {
      this.menuNav.classList.add('layout-header--home');
    }
  }
}
