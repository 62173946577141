import mapStyle from './mapStyle.json';

export function initMap(google) {
  const locationParis = { lat: 48.8534, lng: 2.3488 };
  const map = new google.maps.Map(document.getElementById('map'), {
    center: locationParis,
    zoom: 13,
    disableDefaultUI: true,
    styles: mapStyle
  });

  checkExistingMarkers(map);
}

function checkExistingMarkers(map) {
  let addresses = [];
  const pickupAddress = document.getElementById('card-pickup-address');
  const deliveryAddress = document.getElementById('card-delivery-address');

  if (null !== pickupAddress && '' !== pickupAddress.innerHTML) {
    addresses.push(pickupAddress.innerHTML);
  }

  if (null !== deliveryAddress && '' !== deliveryAddress.innerHTML) {
    addresses.push(deliveryAddress.innerHTML);
  }

  if (addresses.length > 0) {
    getMarkers(addresses).then(data => {
      addMarkers(data, map);
    });
  }
}

async function getMarkers(addresses) {
  let markers = [];

  await asyncForEach(addresses, async address => {
    await getLocation(address).then(data => {
      markers.push(data);
    });
  });

  return markers;
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

async function getLocation(address) {
  return await new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK') {
        resolve(results[0]);
      } else {
        reject(status);
      }
    });
  });
}

function addMarkers(markersList, map) {
  const bounds = new google.maps.LatLngBounds();
  const icons = [
    'app/themes/coursiervelo/assets/img/map-pin-blue.png',
    'app/themes/coursiervelo/assets/img/map-pin-pink.png'
  ];

  for (let i = 0; i < markersList.length; i++) {
    const latLng = new google.maps.LatLng(
      markersList[i].geometry.location.lat(),
      markersList[i].geometry.location.lng()
    );
    new google.maps.Marker({
      position: latLng,
      icon: icons[i],
      map: map
    });
    // add coordinates to bounds
    bounds.extend(latLng);
  }

  // set map bounds if multiple markers
  if (markersList.length === 1) {
    map.setZoom(15);
    map.panTo(
      new google.maps.LatLng(
        markersList[0].geometry.location.lat(),
        markersList[0].geometry.location.lng()
      )
    );
  } else {
    map.fitBounds(bounds);
  }
}
