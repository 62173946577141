module.exports = {
  isTouchDevice: function() {
    return 'ontouchstart' in document.documentElement;
  },
  isIE: function () {
    return !!window.MSInputMethodContext && !!document.documentMode;
  },
  easterDay: function(Y) {
    let M=3,
        G= Y % 19+1,
        C= ~~(Y/100)+1,
        L=~~((3*C)/4)-12,
        E=(11*G+20+ ~~((8*C+5)/25)-5-L)%30,
        D;

    E<0 && (E+=30);
    (E==25 && G>11 || E==24) && E++;
    (D=44-E)<21 && (D+=30);
    (D+=7-(~~((5*Y)/4)-L-10+D)%7)>31 && (D-=31,M=4);

    return [Y, M, D];
  }
};
