import 'whatwg-fetch';
import 'babel-polyfill';
import $ from 'jquery';

export default class UpdatePrice {
  constructor() {
    this.pickupAddress = document.getElementById('command_pickup_address');
    this.deliveryAddress = document.getElementById('command_delivery_address');
    this.cardPickupAddress = document.getElementById('card-pickup-address');
    this.cardDeliveryAddress = document.getElementById('card-delivery-address');
    this.price = document.querySelector('.l-orderCard__headPrice');
    this.deliveryOptions = document.getElementsByName(
      'command[delivery][options][]'
    );
    this.commandTypes = document.getElementsByName('command[transport][type]');
    this.deliveryTypes = document.getElementsByName(
      'command[transport][delivery_type]'
    );
    this.cardDeliveryType = document.getElementById('card-delivery-type');
    this.cardDeliveryTypeDelivery = document.getElementById(
      'card-delivery-deliveryType'
    );

    this.init();
  }

  init() {
    if (this.deliveryAddress && this.deliveryAddress.value) {
      handleUpdate(this.deliveryAddress);
    }

    if (this.commandTypes) {
      this.commandTypes.forEach(option => {
        option.addEventListener('change', () => {
          // if one option changes and both pickup and delivery addresses are set, the price is updated.
          if (
            null !== this.cardPickupAddress &&
            null !== this.cardDeliveryAddress
          ) {
            updatePrice(
              this.cardPickupAddress.innerHTML,
              this.cardDeliveryAddress.innerHTML
            ).then(data => {
              let price = +data[0];

              if (isNaN(price)) {
                price = 0;
              }

              this.price.innerHTML = price + ' €';
              this.cardDeliveryType.innerHTML =
                option.value === 'VC' ? 'Vélo Cargo' : 'Vélo';
            });
          }
        });
      });
    }

    if (this.deliveryTypes) {
      this.deliveryTypes.forEach(option => {
        option.addEventListener('change', () => {
          // if one option changes and both pickup and delivery addresses are set, the price is updated.
          if (
            null !== this.cardPickupAddress &&
            null !== this.cardDeliveryAddress
          ) {
            updatePrice(
              this.cardPickupAddress.innerHTML,
              this.cardDeliveryAddress.innerHTML
            ).then(data => {
              let price = +data[0];

              if (isNaN(price)) {
                price = 0;
              }

              this.price.innerHTML = price + ' €';
              if (option.value === '1') {
                this.cardDeliveryTypeDelivery.innerHTML = 'Standard';
              } else if (option.value === '2') {
                this.cardDeliveryTypeDelivery.innerHTML = 'Urgent';
              } else {
                this.cardDeliveryTypeDelivery.innerHTML = 'Exclu';
              }
            });
          }
        });
      });
    }

    if (this.deliveryOptions) {
      this.deliveryOptions.forEach(option => {
        option.addEventListener('change', () => {
          const pickupAddress = document.getElementById('card-pickup-address');
          const deliveryAddress = document.getElementById(
            'card-delivery-address'
          );

          if (null !== pickupAddress && null !== deliveryAddress) {
            updatePrice(
              pickupAddress.innerHTML,
              deliveryAddress.innerHTML
            ).then(data => {
              let price = +data[0];

              if (isNaN(price)) {
                price = 0;
              }

              this.price.innerHTML = price + ' €';
            });
          }
        });
      });
    }
  }
}

export function handleUpdate(field) {
  const price = document.querySelector('.l-orderCard__headPrice');
  const cardPickupAddress = document.getElementById('card-pickup-address');
  const cardDeliveryAddress = document.getElementById('card-delivery-address');
  const pickupAddressItem = document.getElementById('container-pickup-address');
  const deliveryAddressItem = document.getElementById(
    'container-delivery-address'
  );

  if (
    field.getAttribute('id') === 'command_pickup_address' &&
    cardDeliveryAddress
  ) {
    updatePrice(field.value, cardDeliveryAddress.innerHTML)
      .then(data => {
        let apiError = deliveryAddressItem.querySelector(
          '.l-orderCard__apiError'
        );

        apiError.innerHTML = '';

        if (data.error) {
          apiError.innerHTML = data.error;
          apiError.classList.remove('hidden');
        } else {
          let result = +data[0];

          if (isNaN(result)) {
            result = 0;
          }

          price.innerHTML = result + ' €';
          pickupAddressItem
            .querySelector('.l-orderCard__addressError')
            .classList.add('hidden');
        }
      })
      .catch(error => {
        console.log(error);
        // display error
        pickupAddressItem
          .querySelector('.l-orderCard__addressError')
          .classList.remove('hidden');

        // Empty input address value
        document.getElementById('command_pickup_address').value = '';
      });
  } else if (
    field.getAttribute('id') === 'command_delivery_address' &&
    cardPickupAddress
  ) {
    updatePrice(cardPickupAddress.innerHTML, field.value)
      .then(data => {
        let apiError = deliveryAddressItem.querySelector(
          '.l-orderCard__apiError'
        );

        apiError.innerHTML = '';

        if (data.error) {
          apiError.innerHTML = data.error;
          apiError.classList.remove('hidden');
        } else {
          let result = +data[0];

          if (isNaN(result)) {
            result = 0;
          }

          price.innerHTML = result + ' €';
          deliveryAddressItem
            .querySelector('.l-orderCard__addressError')
            .classList.add('hidden');
        }
      })
      .catch(() => {
        // Display error
        deliveryAddressItem
          .querySelector('.l-orderCard__addressError')
          .classList.remove('hidden');

        // Empty input address value
        document.getElementById('command_delivery_address').value = '';
      });
  }
}

export async function updatePrice(address1, address2) {
  const currentStep = document.getElementById('command_flow_commandFunnel_step')
    .value;
  const instanceId = document.getElementById(
    'command_flow_commandFunnel_instance'
  ).value;
  const deliveryOptions = document.getElementsByName(
    'command[delivery][options][]'
  );
  const deliveryTypes = document.getElementsByName(
    'command[transport][delivery_type]'
  );
  const commandTypes = document.getElementsByName('command[transport][type]');
  let options = [];
  let deliveryType = 'V1';

  if (deliveryTypes.length > 0 || commandTypes.length > 0) {
    deliveryType =
      $("input[name='command[transport][type]']:checked").val() +
      $("input[name='command[transport][delivery_type]']:checked").val();
  }

  if (deliveryOptions.length > 0) {
    deliveryOptions.forEach(option => {
      if (option.checked) {
        options.push(option.value);
      }
    });
  }

  return await fetch('/update-price', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      instanceId: instanceId,
      currentStep: currentStep,
      pickupAddress: address1,
      deliveryAddress: address2,
      deliveryType: deliveryType,
      options: options
    })
  }).then(response => {
    if (response.ok || response.status === 400) {
      return response.json();
    }

    return Promise.reject();
  });
}
