import 'whatwg-fetch';

export default class Payment {
  constructor() {
    this.paymentBtn  = document.getElementById('bouton');
    this.paymentForm = document.getElementById('payment-form');
    this.paymentCGU  = document.getElementById('cgu');

    this.init();
  }

  init() {
    if (this.paymentBtn) {
      // Disabled pay button
      this.paymentBtn.classList.add('cta__link--disabled');
      this.paymentCGU.addEventListener('change', () => {
        // If CGU is checked
        if (this.paymentCGU.checked) {
          // Enable pay button
          this.paymentBtn.classList.remove('cta__link--disabled');
        } else {
          this.paymentBtn.classList.add('cta__link--disabled');
        }
      });

      getPaymentForm(this.paymentForm);
    }
  }
}

export function getPaymentForm(paymentForm) {
  const fetchPromise = fetch('/paiement');
  fetchPromise
    .then(response => {
      return response.json();
    })
    .then(data => {
      const bankForm = data.bankForm;
      // Remove the submit button from the form to use the one already displayed in the following template: footer.html.twig.
      // This allow us to add style to our custom submit button.
      const form = bankForm.replace(
        '<input type="submit" name="bouton" id="bouton" value="Connexion au serveur de paiement" />',
        ''
      );
      paymentForm.innerHTML = form;
    });
}
