import $ from 'jquery';
require('./lib/dataTables.min');
import flatpickr from 'flatpickr';

export default class Table {
  constructor(table) {
    this.table = table;

    if (!this.table) {
      return false;
    }

    this.statusChoiceToggle = document.querySelector(
      '.fieldset__dropdown-choice'
    );

    this.statusChoiceList = document.querySelector(
      '.fieldset__dropdown-choices'
    );

    this.statusChoiceInputs = document.querySelectorAll(
      'input[name="dashboard-filter-status"]'
    );

    this.statusChosenNumber = document.querySelector(
      '.fieldset__dropdown-chosen'
    );

    this.resetFilter = document.querySelectorAll('.-resetFilters');

    this.datepicker = null;

    this.init();
  }

  init() {
    const filterDate = document.getElementById('dashboard__filter-date');
    const filterStatus = document.getElementById('dashboard__filter-status');

    if (filterDate && filterStatus) {
      this.handleFilterDate();
      this.handleFilterStatus();
    }

    if (this.table) {
      this.initDataTable(this.table);
    }

    //Handle reset filter
    this.resetFilter.forEach(resetFilter => {
      resetFilter.addEventListener('click', this.resetFilters.bind(this));
    });
  }

  /**
   * Initialisation of dataTables lib
   * @param  {Object} dashboard Dashboard table element
   */
  initDataTable(dashboard) {
    const deliveryCounter = $('#deliveryCounter');

    // DataTable
    this.datatable = $(dashboard).DataTable({
      paging: true,
      pageLength: 10,
      autoWidth: false,
      serverSide: true,
      ajax: {
        url: '/dashboard',
        contentType: 'application/json',
        data: request => {
          return $.extend({}, request, {
            filterStartDate:
              typeof this.datepicker.selectedDates[0] !== 'undefined'
                ? this.datepicker.selectedDates[0].toLocaleDateString()
                : false,
            filterEndDate:
              typeof this.datepicker.selectedDates[1] !== 'undefined'
                ? this.datepicker.selectedDates[1].toLocaleDateString()
                : false,
            filterStatus: $('input[name="dashboard-filter-status"]:checked')
              .toArray()
              .map(el => $(el).val())
          });
        }
      },
      drawCallback: settings => {
        const data = settings.json;
        const elResults = document.querySelector('.dataTables_wrapper');
        const elEmpty = document.querySelector(
          '.layout-dashboard__content.-empty'
        );

        deliveryCounter.text(
          `(${
            data.data.length > 1
              ? data.data.length + ' commandes'
              : data.data.length + ' commande'
          })`
        );

        JSON.parse(localStorage.getItem('dataTablesData'));

        // If no results when filters
        if (data.recordsFiltered === 0) {
          // Show empty div
          elEmpty.classList.remove('hidden');
          // Hide result tables
          elResults.classList.add('hidden');
        } else {
          // Hide empty div
          elEmpty.classList.add('hidden');
          // Show result tables
          elResults.classList.remove('hidden');
        }
      },
      order: [[0, 'desc']],
      createdRow: function(row, data) {
        if (data.deliveryStatus === 1 || data.deliveryStatus === 2) {
          $(row).addClass('pending');
        }
        $(row).addClass('layout-dashboard__table-list');
      },
      columns: [
        {
          name: 'pickup.date',
          orderable: true,
          searchable: false,
          data: 'pickupDate',
          className: 'layout-dashboard__table-item date',
          render: data => {
            return new Date(data).toLocaleDateString('fr-FR');
          }
        },
        {
          name: 's.reference',
          orderable: true,
          searchable: false,
          data: 'commandName',
          className: 'layout-dashboard__table-item title'
        },
        {
          name: 'pickup.address',
          orderable: false,
          searchable: false,
          data: 'pickupAddress',
          className: 'layout-dashboard__table-item pickup hidden-xs',
          render: data => {
            return `
              <svg class="icon icon-map-pin">
                <use xlink:href="#icon-map-pin"></use>
              </svg>
              <span>${data}</span>
            `;
          }
        },
        {
          name: 'delivery.address',
          orderable: false,
          searchable: false,
          data: 'deliveryAddress',
          className: 'layout-dashboard__table-item delivery hidden-xs',
          render: data => {
            return `
                <svg class="icon icon-map-pin">
                    <use xlink:href="#icon-map-pin"></use>
                </svg>
                <span>${data}</span>
            `;
          }
        },
        {
          name: 'transport.deliveryType',
          orderable: true,
          searchable: false,
          data: 'deliveryType',
          className: 'layout-dashboard__table-item type hidden-xs',
          render: data => {
            switch (data) {
              case '1':
                return 'Standard';
              case '2':
                return 'Urgent';
              case '3':
                return 'Exclu ⚡';
            }
          }
        },
        {
          name: 's.status',
          orderable: true,
          searchable: false,
          data: 'deliveryStatus',
          className: 'layout-dashboard__table-item status',
          render: data => {
            let label = '', className = '';

            switch (data) {
              case '1':
                label = 'Confirmé';
                className = 'start';
                break;
              case '2':
                label = 'En route';
                className = 'middle';
                break;
              case '3':
                label = 'Livré !';
                className = 'end';
                break;
            }

            return `
              <div class="progress progress--${className}">
                  <span class="progress__bar"></span>
                  <p class="progress__status">${label}</p>
              </div>
              `;
          }
        },
        {
          name: 's.price',
          orderable: true,
          searchable: false,
          data: 'price',
          className: 'layout-dashboard__table-item price',
          render: data => {
            return data.replace('.', ',') + '€';
          }
        },
        {
          name: 's.quoteURL',
          orderable: false,
          searchable: false,
          data: 'quote',
          className: 'layout-dashboard__table-item bill hidden-xs',
          render: data => {
            return `
              <a href="${data}">
                  <svg class="icon icon-download">
                      <use xlink:href="#icon-download"></use>
                  </svg>
                  <span>Télécharger la facture</span>
              </a>
            `;
          }
        }
      ],
      processing: true,
      searching: false,
      language: {
        zeroRecords: '',
        processing:
          "<div class='dataTables__loader'><div class='dataTables__loader-dot'></div></div>",
        paginate: {
          next: '&#8594;', // or '→'
          previous: '&#8592;' // or '←'
        }
      }
    });

    $('input[name="dashboard-filter-status"]').on('change', () => {
      this.datatable.draw();
    });

    $(this.datepicker.element).on('change', () => {
      if (
        typeof this.datepicker.selectedDates[0] !== 'undefined' &&
        typeof this.datepicker.selectedDates[1] !== 'undefined'
      ) {
        this.datatable.draw();
      }
    });
  }

  /**
   * Filter date range function
   */
  handleFilterDate() {
    let self = this;
    // Init datepicker for date range selection
    this.datepicker = flatpickr('#dashboard-filter-date', {
      appendTo: document.getElementById('datepicker'),
      mode: 'range',
      dateFormat: 'd/m/Y',
      nextArrow: `<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.195262 0.195262C0.455612 -0.0650874 0.877722 -0.0650874 1.13807 0.195262L4.4714 3.5286C4.73175 3.78894 4.73175 4.21106 4.4714 4.4714L1.13807 7.80474C0.877722 8.06509 0.455612 8.06509 0.195262 7.80474C-0.0650874 7.54439 -0.0650874 7.12228 0.195262 6.86193L3.05719 4L0.195262 1.13807C-0.0650874 0.877722 -0.0650874 0.455612 0.195262 0.195262Z" fill="#3A4161"/>
        </svg><svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.195262 0.195262C0.455612 -0.0650874 0.877722 -0.0650874 1.13807 0.195262L4.4714 3.5286C4.73175 3.78894 4.73175 4.21106 4.4714 4.4714L1.13807 7.80474C0.877722 8.06509 0.455612 8.06509 0.195262 7.80474C-0.0650874 7.54439 -0.0650874 7.12228 0.195262 6.86193L3.05719 4L0.195262 1.13807C-0.0650874 0.877722 -0.0650874 0.455612 0.195262 0.195262Z" fill="#3A4161"/>
        </svg>`,
      prevArrow: `<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.4714 0.195262C4.73175 0.455612 4.73175 0.877722 4.4714 1.13807L1.60948 4L4.4714 6.86193C4.73175 7.12228 4.73175 7.54439 4.4714 7.80474C4.21106 8.06509 3.78894 8.06509 3.5286 7.80474L0.195262 4.4714C-0.0650874 4.21106 -0.0650874 3.78894 0.195262 3.5286L3.5286 0.195262C3.78894 -0.0650874 4.21106 -0.0650874 4.4714 0.195262Z" fill="#3A4161"/>
        </svg><svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.4714 0.195262C4.73175 0.455612 4.73175 0.877722 4.4714 1.13807L1.60948 4L4.4714 6.86193C4.73175 7.12228 4.73175 7.54439 4.4714 7.80474C4.21106 8.06509 3.78894 8.06509 3.5286 7.80474L0.195262 4.4714C-0.0650874 4.21106 -0.0650874 3.78894 0.195262 3.5286L3.5286 0.195262C3.78894 -0.0650874 4.21106 -0.0650874 4.4714 0.195262Z" fill="#3A4161"/>
        </svg>`,
      onClose: function() {
        document.querySelector('.block-datepicker').classList.add('hidden');
      },
      onOpen: function() {
        document.querySelector('.block-datepicker').classList.remove('hidden');
      }
    });
  }

  /**
   * Filter status function
   */
  handleFilterStatus() {
    // On click on status filter element
    this.statusChoiceToggle.addEventListener('click', () => {
      // We toggle the hidden class on dropdown statuses list
      this.statusChoiceList.classList.toggle('hidden');
      // We init the total number of selected statuses to 0
      let statusChoiceNumber = 0;

      // If the dropdown statuses list is hidden
      if (this.statusChoiceList.classList.contains('hidden')) {
        // We check for each status in it
        this.statusChoiceInputs.forEach(statusChoiceInput => {
          // If it's checked
          if (statusChoiceInput.checked) {
            // We implement the total number of selected statuses
            statusChoiceNumber += 1;
          }
        });

        // Then if there is one selected status at least
        if (statusChoiceNumber > 0) {
          // We show the div
          this.statusChosenNumber.classList.remove('hidden');
          // With total number of selected statuses in it
          this.statusChosenNumber.innerHTML = statusChoiceNumber;
        } else {
          // Else we hide and reset this div
          this.statusChosenNumber.classList.add('hidden');
          this.statusChosenNumber.innerHTML = '';
        }
      }
    });
  }

  //Reset filters then refresh table
  resetFilters() {
    this.resetFilterDate();
    this.resetFilterStatus();

    this.datatable.draw();
  }

  /**
   * Reset filter date function
   */
  resetFilterDate() {
    this.datepicker.clear();
  }

  /**
   * Reset filter status function
   */
  resetFilterStatus() {
    // We uncheck all the statuses checkboxes
    this.statusChoiceInputs.forEach(statusChoiceInput => {
      statusChoiceInput.checked = false;
    });

    // And reset the total number of selected statuses
    this.statusChosenNumber.classList.add('hidden');
    this.statusChosenNumber.innerHTML = '';
  }
}
