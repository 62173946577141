import 'whatwg-fetch';
const Map = require('./map');
const UpdatePrice = require('./updatePrice');

export default class Autocomplete {
  init() {
    const hasGoogleAutocomplete = document.querySelector(
      '.google-autocomplete'
    );
    const orderForm = document.querySelector('.layout-order__forms');
    const homeForm = document.querySelector('.layout-hero__form');

    if (orderForm && google) {
      Map.initMap(google);

      // Disable enter for form validation
      // To let google autocomplete enter selection working
      orderForm.addEventListener('keydown', e => {
        if (e.keyCode == 13) {
          e.preventDefault();
          return false;
        }
      });
    }

    if (homeForm && google) {
      // Disable enter for form validation
      // To let google autocomplete enter selection working
      homeForm.addEventListener('keydown', e => {
        if (e.keyCode == 13) {
          e.preventDefault();
          return false;
        }
      });
    }

    if (hasGoogleAutocomplete && google) {
      this.pickupAddress = document.getElementById('command_pickup_address');
      this.deliveryAddress = document.getElementById(
        'command_delivery_address'
      );
      this.billingAddress = document.getElementById('command_billing_address');
      this.containerPickupAddress = document.querySelector(
        '#container-pickup-address'
      );
      this.containerDeliveryAddress = document.querySelector(
        '#container-delivery-address'
      );
      this.quotePickupAddress = document.getElementById(
        'quote_pickupAddress_address'
      );
      this.quoteDeliveryAddress = document.getElementById(
        'quote_deliveryAddress_address'
      );
      this.cardPickupAddress = document.getElementById('card-pickup-address');
      this.cardDeliveryAddress = document.getElementById(
        'card-delivery-address'
      );

      this.initAddressAutocomplete();
    }
  }

  initAddressAutocomplete() {
    // Create the autocomplete object, restricting the search predictions to geographical location types.
    const commandAddressPickup = document.getElementById(
      'command_addresses_pickupAddress'
    );
    const commandAddressDelivery = document.getElementById(
      'command_addresses_deliveryAddress'
    );

    if (this.pickupAddress) {
      this.initAutocomplete(this.pickupAddress);
    }

    if (this.deliveryAddress) {
      this.initAutocomplete(this.deliveryAddress);
    }

    if (this.quotePickupAddress) {
      this.initAutocomplete(this.quotePickupAddress);
    }

    if (this.quoteDeliveryAddress) {
      this.initAutocomplete(this.quoteDeliveryAddress);
    }

    if (this.billingAddress) {
      this.initAutocomplete(this.billingAddress);
    }

    if (commandAddressPickup && commandAddressDelivery) {
      this.initAutocomplete(commandAddressPickup);
      this.initAutocomplete(commandAddressDelivery);
    }
  }

  initAutocomplete(field) {
    const autocomplete = new google.maps.places.Autocomplete(field, {
      types: ['geocode'],
      componentRestrictions: { country: 'fr' }
    });
    autocomplete.setFields(['address_component']);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.modifyAutocomplete(autocomplete, field);
    });
  }

  modifyAutocomplete(autocomplete, field) {
    const place = autocomplete.getPlace();
    const homepage = document.querySelector('.page--homepage');
    let address = [];

    address['street_number'] = address['route'] = address['locality'] = address[
      'postal_code'
    ] = address['country'] = '';

    for (let i = 0; i < place.address_components.length; i++) {
      for (let j = 0; j < place.address_components[i].types.length; j++) {
        if (
          [
            'street_number',
            'route',
            'locality',
            'postal_code',
            'country'
          ].includes(place.address_components[i].types[j])
        ) {
          address[place.address_components[i].types[j]] =
            place.address_components[i].long_name;
        }
      }
    }

    // Format address to display all needed elements in field (especially zipcode)
    if (0 !== address['street_number'].length) {
      field.value = `${address['street_number']} ${address['route']}, ${
        address['postal_code']
      } ${address['locality']}, ${address['country']}`;
    }

    field.setAttribute('value', field.value);

    if (null === homepage) {
      this.updateCard(field);
      UpdatePrice.handleUpdate(field);
    }
  }

  updateCard(field) {
    if (field.getAttribute('id') === 'command_pickup_address') {
      this.cardPickupAddress.innerHTML = field.value.replace(/\\/g, '');
      this.containerPickupAddress.classList.remove('hidden');
    }

    if (field.getAttribute('id') === 'command_delivery_address') {
      this.cardDeliveryAddress.innerHTML = field.value.replace(/\\/g, '');
      this.containerDeliveryAddress.classList.remove('hidden');
    }

    Map.initMap(google);
  }
}
