import Rellax from 'rellax';
import anime from 'animejs/lib/anime.es.js';

export default class Animation {
  constructor() {
    this.homepage = document.querySelector('.page--homepage');
    this.init();
  }

  init() {
    this.rellax();

    if (this.homepage) {
      this.viewportDetection();
    }
  }

  rellax() {
    // To enable rellax only on desktop
    const desktop = window.matchMedia('(min-width: 1200px)');
    if (desktop.matches) {
      const rellax = new Rellax('.rellax');
    }
  }

  // To lauch animation steed only when is in the viewport
  viewportDetection() {
    const delivery = document.querySelector('.section--delivery');
    const roadlineSvg = document.querySelector('.roadline');

    const windowHeight = window.innerHeight;

    const deliveryTop = delivery.getBoundingClientRect().top;
    const deliveryBottom = delivery.getBoundingClientRect().bottom;
    const deliveryMidTop = deliveryTop - windowHeight / 2;
    const deliveryMidBottom = deliveryBottom + windowHeight / 3;

    window.addEventListener('scroll', () => {
      const windowTopPosition = window.scrollY;
      const windowBottomPosition = windowTopPosition + windowHeight;

      if (
        windowTopPosition > deliveryMidTop &&
        windowBottomPosition < deliveryMidBottom
      ) {
        if (!roadlineSvg.classList.contains('is-animate')) {
          roadlineSvg.classList.add('is-animate');
          this.animMapSteed();
        }
      } else {
        if (roadlineSvg.classList.contains('is-animate')) {
          roadlineSvg.classList.remove('is-animate');
          // Cancel mapSteed animation
          if (this.mapSteed) {
            anime.remove(this.mapSteed);
            this.mapSteed.style = '';
          }
        }
      }
    });
  }

  // Steed follows path
  animMapSteed() {
    const myPath = anime.path('.map path');
    this.mapSteed = document.querySelector('.map .map__steed');

    anime({
      targets: this.mapSteed,
      translateX: myPath('x'),
      translateY: myPath('y'),
      rotate: myPath('angle'),
      easing: 'linear',
      duration: 12000
    });
  }
}
