export default class Faq {
  constructor() {
    this.init();
  }

  init() {
    this.manageFaq();
  }

  manageFaq() {
    const titles = document.querySelectorAll('.faq__title');
    const subtitles = document.querySelectorAll('.faq__wrapper--question');

    if (titles && titles.length > 0) {
      [...titles].map((title, titleIndex) => {
        const questionWrapper = document.querySelectorAll('.faq__questions');

        // Manage click on title
        title.addEventListener('click', () => {
          // Manage title class
          if (!title.classList.contains('is-clicked')) {
            title.classList.add('is-clicked');
          }

          // FIXME: Workaround
          [...titles].map((title, index) => {
            if (index !== titleIndex) {
              title.classList.remove('is-clicked');
            }
          });

          // Manage question display
          if (questionWrapper && questionWrapper.length > 0) {
            [...questionWrapper].map((category, categoryIndex) => {
              if (titleIndex === categoryIndex) {
                if (!category.classList.contains('is-displayed')) {
                  category.classList.add('is-displayed');
                }
              } else {
                if (category.classList.contains('is-displayed')) {
                  category.classList.remove('is-displayed');
                }
              }
            });
          }
        });
      });
    }

    // Open/Close individuals questions
    if (subtitles && subtitles.length > 0) {
      [...subtitles].map((subtitle, subtitleIndex) => {
        subtitle.addEventListener('click', () => {
          // Manage answer opening
          if (!subtitle.classList.contains('is-clicked')) {
            subtitle.classList.add('is-clicked');
            const faqAnswer = subtitle.nextElementSibling;
            faqAnswer.classList.add('is-displayed');
          }

          // Manage answer closing
          [...subtitles].map((subtitle, index) => {
            if (index !== subtitleIndex) {
              const faqAnswer = subtitle.nextElementSibling;
              subtitle.classList.remove('is-clicked');
              faqAnswer.classList.remove('is-displayed');
            }
          });
        });
      });
    }
  }
}
