export default class Popin {
  constructor(element) {
    if (!element) {
      return;
    }

    if (element.__PopinInstance__ instanceof Popin) {
      return element.__PopinInstance__;
    }

    this.element = element;

    this.listen();

    this.element.__PopinInstance__ = this;
  }

  listen() {
    this.element.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();

      const scrollPos = window.scrollY;
      // Prevent page to scroll
      (document.body || document.documentElement).style.overflow = 'hidden';
      // Block page to scrolled position
      (document.body || document.documentElement).style.top = `-${scrollPos}px`;

      const popins = document.querySelectorAll('.layout-modal');
      popins.forEach(popin => {
        popin.classList.add('hidden');
      });
      const popin = document.querySelector(
        '#' + this.element.getAttribute('data-popin')
      );
      const popinClose =
        popin.querySelector('.layout-modal__close') ||
        popin.querySelector('.-close');

      popin.classList.remove('hidden');

      // On close popin
      popinClose &&
        popinClose.addEventListener('click', () => {
          popin.classList.add('hidden');
          (document.body || document.documentElement).style.overflow = '';
          (document.body || document.documentElement).style.top = '';
        });
    });
  }
}
