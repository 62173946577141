import 'whatwg-fetch';
import $ from 'jquery';

export default class Quote {
    constructor() {
        this.quote            = document.getElementById('quote_amount');
        this.form             = document.getElementById('quote_form');
        this.pickupAddress    = document.getElementById('quote_pickupAddress_address');
        this.deliveryAddress  = document.getElementById('quote_deliveryAddress_address');

        this.init();
    }

    init() {
        this.form.addEventListener('submit', (event) => {

            // remove field error if exists
            if (this.pickupAddress.nextElementSibling && this.pickupAddress.nextElementSibling.classList.contains('errors-container')) {
                this.pickupAddress.nextElementSibling.remove();
            }

            // remove field error if exists
            if (this.deliveryAddress.nextElementSibling && this.deliveryAddress.nextElementSibling.classList.contains('errors-container')) {
                this.deliveryAddress.nextElementSibling.remove();
            }

            event.preventDefault();
            this.handleSubmit();
        })
    }

    handleSubmit() {
        try {
            this.getQuote();
        } catch {
            console.error(error);
        }
    }

    async getQuote() {
        const form = $('#quote_form');

        return $.ajax({
            type:    "POST",
            url:     '/devis',
            data:    form.serialize(),
            success: data => {

                // add error message if address format is not valid
                if (data.message === 'Invalid form') {
                    for (let key in data.data) {
                        $(form.find('[id*="quote_' + key + '_address"]')[0]).after('<ul class="errors-container"><li class="fieldset__error">' + data.data[key] + '</li></ul>');
                    }
                } else {

                    // add error message if address is located in a bad area
                    if (!data.errors) {
                        this.quote.innerHTML = `${data.price} €`;

                        // display quote amount if form fiels are valid
                    } else {
                        const message = 'L\'adresse renseignée ne fait pas partie des zones desservies';
                        data.errors.forEach(error => {
                            $(form.find('[id*="quote_' + error + '_address"]')[0]).after('<ul class="errors-container"><li class="fieldset__error">' + message + '</li></ul>');
                        });
                    }
                }
            }
        });
    }
}
