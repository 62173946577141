export default class Account {
  constructor() {
    this.deactivateAccountForm = document.getElementById('deactivateAccount');
    this.init();
  }

  init() {
    this.managePersonalSpace();

    if (this.deactivateAccountForm) {
      this.deactivateAccount();
    }
  }

  managePersonalSpace() {
    // Open/Close form on mobile
    const formsTitle = document.querySelectorAll('.form__title');

    if (formsTitle && formsTitle.length > 0) {
      [...formsTitle].forEach((item) => {
        item.addEventListener('click', () => {
          item.classList.toggle('is-clicked');
          const formWrapper = item.closest('.form');

          if (formWrapper) {
            const formContent = formWrapper.querySelector('.form__inner--hidden');

            formContent && formContent.classList.toggle('is-displayed');
          }
        });
      });
    }
  }

  deactivateAccount() {
    const deactivateButton = this.deactivateAccountForm.querySelector('.cta');
    const passwordField = this.deactivateAccountForm.querySelector('input[type="password"]');

    passwordField.addEventListener('input', (e) => {
      if(e.target.value !== '') {
        deactivateButton.classList.remove('cta__link--disabled');
      } else {
        if(!deactivateButton.classList.contains('cta__link--disabled')) {
          deactivateButton.classList.add('cta__link--disabled');
        }
      }
    });
  }
}
