import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr.js';
import $ from 'jquery';
import { easterDay } from './utils';

flatpickr.localize(French); // default locale is now French

export default class Form {
  constructor() {
    this.form = document.getElementById('order');
    this.inputDate = document.querySelector('#pickup-date');

    this.submitCta = document.querySelector(
      'div.layout-order__bottombar > nav > div > button'
    );
    this.orderLaterChoice = document.getElementById(
      'command_pickup_timeChoice_1'
    );

    // payment
    this.paymentBtn = document.getElementById('payment-btn');
    this.paymentFields = [];
    this.paymentFields['pan'] = $('.kr-pan');
    this.paymentFields['security'] = $('.kr-security-code');
    this.paymentFields['expiry'] = $('.kr-expiry');

    this.focused = null;
    this.flowStep = document.getElementById('command_flow_commandFunnel_step');
    this.popin = document.getElementById('popin-confirmation');
    this.confirmationForm = document.getElementById('confirmation');
    this.formTitle = document.querySelector('.confirmationForm__title');
    this.formIcon = document.querySelector('.confirmationForm__icon');
    this.formDescription = document.querySelector(
      '.confirmationForm__description'
    );
    this.animWheelFront = document.querySelector(
      '.icon-rolling-bike-wheel-front'
    );
    this.animWheelBack = document.querySelector(
      '.icon-rolling-bike-wheel-back'
    );
    this.animRoad = document.querySelector('.animation-confirm__road');

    this.init();
  }

  init() {
    this.initEvents();

    if (document.getElementById('pickup-date')) {
      this.initDatePicker();
    }

    if (this.inputDate) {
      this.inputDate.addEventListener('change', () => {
        const inputTime = document.querySelector('#command_pickup_date');
        const regex = /-/gi;
        inputTime.value = this.inputDate.value.replace(regex, '/');
      });
    }

    if (null !== this.flowStep && '2' === this.flowStep.value) {
      const containerDeliveryAddress = document.getElementById(
        'container-delivery-address'
      );
      const deliveryAddress = document.getElementById(
        'command_delivery_address'
      );

      if ('' !== deliveryAddress.value) {
        document.getElementById('card-delivery-address').innerHTML =
          deliveryAddress.value;
        containerDeliveryAddress.classList.remove('hidden');
      }
    }

    if (typeof KR !== 'undefined') {
      this.handlePaymentStep();
    }
  }

  handlePaymentStep() {
    // Handle focus and blur
    KR.post('/event/listen', {
      event: 'krypton.field.event',
      callback: function(t, e) {
        if (e.type === 'triggerFocusActions') {
          $('.kr-'.concat(e.name)).addClass('kr-focus');
        } else if (e.type === 'blur') {
          $('.kr-'.concat(e.name)).removeClass('kr-focus');
        }
      }
    });

    //Handle submit
    KR.onFormReady(() => {
      this.paymentBtn.addEventListener('click', e => {
        e.preventDefault();

        // Valide custom fields
        if (this.customLocalValidation()) {
          this.popin.classList.remove('hidden');
          KR.submit();
        }
      });
    });

    KR.onSubmit(event => {
      const url = '/verification';
      if (event.clientAnswer.orderStatus === 'PAID') {
        $.ajax({
          type: 'POST',
          url: url,
          data: event,
          dataType: 'json',
          success: response => {
            this.handleAjaxResponse(response);
          }
        });
      }

      return false;
    });

    KR.onError(event => {
      if (event.errorCode === 'CLIENT_300') {
        event.children.forEach(error => {
          this.setPaymentError(error.errorCode, error.field);
        });
      } else {
        this.setPaymentError(event.errorCode, event.field);
      }
    });
  }

  handleAjaxResponse(response) {
    if (200 === response) {
      $.get('/confirmation');
      this.confirmationForm.classList.remove('hidden');
      this.formIcon.classList.remove('hidden');
      this.formTitle.innerHTML = "C'est parti !";
      this.formDescription.innerHTML =
        '<span class="confirmationForm__span">Tic tac tic tac</span>, un coursier va prendre en charge votre course.';
      this.animWheelBack.style.animation = 'none';
      this.animWheelFront.style.animation = 'none';
      this.animRoad.style.animation = 'none';
    } else {
      $.get('/commande');
    }
  }

  customLocalValidation() {
    // Remove previous errors
    $('.fieldset__error').text('');
    $('.form__error').text('');

    const valid = this.form.checkValidity();
    $(this.form)
      .find(':invalid')
      .each((index, el) => {
        $('.error-'.concat(el.name)).text(el.title);
      });

    return valid;
  }

  setPaymentError(code, field) {
    const input = $('.error-'.concat(field));
    switch (code) {
      case 'CLIENT_301':
        input.text('Numéro de carte invalide');
        break;

      case 'CLIENT_302':
        input.text("Date d'expiration invalide");
        break;

      case 'CLIENT_303':
        input.text('Cryptogramme invalide');
        break;

      case 'CLIENT_304':
        input.text('Ce champs est requis');
        break;

      default:
        $('.form__error').text(
          "Une erreur inattendue s'est produite, veuillez réessayer."
        );
        break;
    }
  }

  initDatePicker() {
    const that = this;
    const d = new Date();
    let h = d.getHours();
    let minHour = '';
    if (h.toString().length === 1) {
      minHour = '0' + h;
    }
    minHour = minHour + ':00';

    if (h >= 18 || h <= 9) {
      document.getElementById('command_pickup_timeChoice_0').disabled = true;
    }
    const datepicker = flatpickr('#pickup-date', {
      appendTo: document.getElementById('datepicker'),
      enableTime: true,
      disableMobile: true,
      disable: [
        function(date) {
          // return true to disable
          return date.getDay() === 0 || date.getDay() === 6;
        },
        function(date) {
          const closed = [
            [1, 0],
            [1, 4],
            [8, 4],
            [14, 6],
            [15, 7],
            [1, 10],
            [11, 10],
            [25, 11]
          ];

          for (let data of closed) {
            if (date.getDate() === data[0] && date.getMonth() === data[1]) {
              return true;
            }
          }
        },
        function(date) {
          const [year, month, day] = easterDay(date.getFullYear());
          const easterDate = new Date(year, month - 1, day);

          // Lundi de Pâques
          if (
            date.getDate() === easterDate.getDate() + 1 &&
            date.getMonth() === easterDate.getMonth()
          ) {
            return true;
          }

          const ascension = new Date(
            easterDate.getTime() + 39 * 24 * 60 * 60 * 1000
          );
          if (
            date.getDate() === ascension.getDate() &&
            date.getMonth() === ascension.getMonth()
          ) {
            return true;
          }

          const pentecote = new Date(
            easterDate.getTime() + 50 * 24 * 60 * 60 * 1000
          );
          if (
            date.getDate() === pentecote.getDate() &&
            date.getMonth() === pentecote.getMonth()
          ) {
            return true;
          }
        }
      ],
      minDate: 'today',
      minTime: minHour,
      maxTime: '18:00',
      dateFormat: 'd-m-Y H:i',
      nextArrow: `<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.195262 0.195262C0.455612 -0.0650874 0.877722 -0.0650874 1.13807 0.195262L4.4714 3.5286C4.73175 3.78894 4.73175 4.21106 4.4714 4.4714L1.13807 7.80474C0.877722 8.06509 0.455612 8.06509 0.195262 7.80474C-0.0650874 7.54439 -0.0650874 7.12228 0.195262 6.86193L3.05719 4L0.195262 1.13807C-0.0650874 0.877722 -0.0650874 0.455612 0.195262 0.195262Z" fill="#3A4161"/>
        </svg><svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.195262 0.195262C0.455612 -0.0650874 0.877722 -0.0650874 1.13807 0.195262L4.4714 3.5286C4.73175 3.78894 4.73175 4.21106 4.4714 4.4714L1.13807 7.80474C0.877722 8.06509 0.455612 8.06509 0.195262 7.80474C-0.0650874 7.54439 -0.0650874 7.12228 0.195262 6.86193L3.05719 4L0.195262 1.13807C-0.0650874 0.877722 -0.0650874 0.455612 0.195262 0.195262Z" fill="#3A4161"/>
        </svg>`,
      prevArrow: `<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.4714 0.195262C4.73175 0.455612 4.73175 0.877722 4.4714 1.13807L1.60948 4L4.4714 6.86193C4.73175 7.12228 4.73175 7.54439 4.4714 7.80474C4.21106 8.06509 3.78894 8.06509 3.5286 7.80474L0.195262 4.4714C-0.0650874 4.21106 -0.0650874 3.78894 0.195262 3.5286L3.5286 0.195262C3.78894 -0.0650874 4.21106 -0.0650874 4.4714 0.195262Z" fill="#3A4161"/>
        </svg><svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.4714 0.195262C4.73175 0.455612 4.73175 0.877722 4.4714 1.13807L1.60948 4L4.4714 6.86193C4.73175 7.12228 4.73175 7.54439 4.4714 7.80474C4.21106 8.06509 3.78894 8.06509 3.5286 7.80474L0.195262 4.4714C-0.0650874 4.21106 -0.0650874 3.78894 0.195262 3.5286L3.5286 0.195262C3.78894 -0.0650874 4.21106 -0.0650874 4.4714 0.195262Z" fill="#3A4161"/>
        </svg>`,
      onClose: function() {
        document.querySelector('.block-datepicker').classList.add('hidden');
      },
      onOpen: function() {
        document.querySelector('.block-datepicker').classList.remove('hidden');
      },
      onChange: function(selectedDates) {
        that.hideErrors();

        // Not allowed before today
        if (selectedDates < new Date()) {
          document
            .querySelector('#pickup-date-fieldset .fieldset__error.-today ')
            .classList.remove('hidden');
        }

        // Not allowed on week-ends
        if (
          selectedDates[0].getDay() === 0 ||
          selectedDates[0].getDay() === 6
        ) {
          document
            .querySelector('#pickup-date-fieldset .fieldset__error.-weekend')
            .classList.remove('hidden');
        }

        // Not allowed after 7pm & before 9am
        if (
          selectedDates[0].getHours() > 19 ||
          selectedDates[0].getHours() < 9
        ) {
          document
            .querySelector('#pickup-date-fieldset .fieldset__error.-hours')
            .classList.remove('hidden');
        }

        if (that.isToday(selectedDates[0])) {
          if (that.isLessTime(selectedDates[0])) {
            document
              .querySelector(
                '#pickup-date-fieldset .fieldset__error.-today-hours'
              )
              .classList.remove('hidden');
          }
        }
      }
    });

    // Inside the datepicker, when we click on validate, it closes the datepicker
    document
      .querySelector('#pickup-date-fieldset .cta')
      .addEventListener('click', () => {
        datepicker.close();
      });
  }

  initEvents() {
    const inputNow = document.querySelector('.input--now');
    const inputLater = document.querySelector('.input--later');
    const inputPicker = document.querySelector('#pickup-date-fieldset');

    inputNow &&
      inputNow.addEventListener('click', () => {
        // Clear datepicker
        this.datepicker && this.datepicker.clear();

        // Hiden input date picker
        inputPicker.classList.add('hidden');
        this.submitCta.classList.remove('cta__link--disabled');
      });

    inputLater &&
      inputLater.addEventListener('click', () => {
        // Show input date picker
        inputPicker.classList.remove('hidden');

        // Force the focus on input to show the datepicker
        this.inputDate.focus();
      });

    inputLater &&
      inputLater.addEventListener('change', () => {
        if (inputLater.checked) {
          if (!this.checkDate()) {
            this.submitCta.classList.add('cta__link--disabled');
          } else {
            this.submitCta.classList.remove('cta__link--disabled');
          }
        } else {
          this.submitCta.classList.remove('cta__link--disabled');
        }
      });

    if (this.inputDate) {
      this.inputDate.addEventListener('change', () => {
        if (this.checkDate()) {
          this.submitCta.classList.remove('cta__link--disabled');
        } else {
          this.submitCta.classList.add('cta__link--disabled');
        }
      });
    }

    inputPicker &&
      document.getElementById('pickup-date').addEventListener('focus', () => {
        // Show input date picker
        document.querySelector('.block-datepicker').classList.remove('hidden');
      });
  }

  hideErrors() {
    document
      .querySelectorAll('#pickup-date-fieldset .fieldset__error')
      .forEach(error => {
        error.classList.add('hidden');
      });
  }

  checkDate() {
    if (!this.inputDate.value) {
      return false;
    }
    let date, time, day, month, year, hour, minute;

    [date, time] = this.inputDate.value.split(' ');

    [day, month, year] = date.split('-');
    [hour, minute] = time.split(':');

    date = new Date(year, +month - 1, day, hour, minute);
    const today = new Date();

    if (
      date < today ||
      date.getDay() === 0 ||
      date.getDay() === 6 ||
      date.getHours() > 19 ||
      date.getHours() < 9
    ) {
      return false;
    }

    return !(this.isToday(date) && this.isLessTime(date));
  }

  isToday(date) {
    const today = new Date();

    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  isLessTime(date) {
    const today = new Date();

    return (
      date.getHours() < today.getHours() ||
      (date.getHours() === today.getHours() &&
        date.getMinutes() < today.getMinutes())
    );
  }
}
