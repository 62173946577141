import { tns } from 'tiny-slider/src/tiny-slider';

export default class Slider {
  constructor() {
    this.init();
  }

  init() {
      this.sliderReviews();
  }

  sliderReviews() {
    const reviewsWrapper = document.querySelector('.section--reviews__wrapper');

    if(!reviewsWrapper) {
      return;
    }

    tns({
      container: '.section--reviews__wrapper',
      loop: false,
      items: 1,
      slideBy: 'page',
      startIndex: 0,
      nav: true,    
      autoplay: true,
      speed: 400,
      autoplayButtonOutput: false,
      mouseDrag: true,
      controlsContainer: false,
      responsive: {
          1200: {
              items: 2,
              fixedWidth: 590,
              gutter: 10,
          }
      }
    });
  }
}