import 'babel-polyfill';
import lozad from 'lozad/dist/lozad';
import AOS from 'aos/dist/aos';

import Account from './account';
import Animation from './animation';
import Autocomplete from './autocomplete';
import Faq from './faq';
import Form from './form';
import Header from './header';
import Popin from './popin';
import Quote from './quote';
import Slider from './slider';
import Table from './table';
import UpdatePrice from './updatePrice';
import Payment from './payment';
import TarteAuCitron from './tarteaucitron';

require('../icons/svgxuse.min');

const App = {
  init() {
    // Variables declaration
    const orderForm = document.querySelector('.layout-order__forms');
    const home = document.querySelector('.page--homepage');
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'

    let browser = window.navigator.userAgent;
    let ie = browser.indexOf('MSIE ');
    let table = document.querySelector('.layout-dashboard__table');

    new TarteAuCitron();
    new Account();
    new Animation();
    new Faq();
    new Header();
    new Slider();

    observer.observe();

    // Test for touch events support and if not supported, attach .no-touch class to the HTML tag.
    if (!('ontouchstart' in document.documentElement)) {
      document.documentElement.className += ' no-touch';
    }

    if (ie > 0 || (!!window.MSInputMethodContext && !!document.documentMode)) {
      document.documentElement.className += ' ie';
    }

    if (table) {
      new Table(table);
    }

    if (home) {
      window.autocompleteParigo = new Autocomplete();
      new Quote();
    }

    if (orderForm) {
      window.autocompleteParigo = new Autocomplete();
      new Form();
      new UpdatePrice();
      new Payment();
    }

    // Create an instance of Popin for each popin
    document.querySelectorAll('[data-popin]').forEach(item => new Popin(item));

    // Init JS Animation library
    AOS.init({ disable: 'mobile' });
  }
};

document.addEventListener('DOMContentLoaded', () => {
  App.init();
});
